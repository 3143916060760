import { KeycloakConfig } from 'keycloak-angular';

let keycloakConfig: KeycloakConfig = {
  url: 'http://192.168.42.25:8080/auth/',
  realm: 'Interno',
  clientId: 'frontend_workflow',
  credentials: {
    secret: "63fef2b5-42d3-45a6-8afa-9e63d36c8283"
  },
};

export const environment = {
  baseApiUrl: 'https://notificaciones-externas-backend-testing.tribcuentasrionegro.gov.ar/api/',
  baseApiUrlChasquiExternal: 'https://notificaciones-externas-backend-testing.tribcuentasrionegro.gov.ar/api/',
  baseApiUrlFileService: 'https://files-testing.tribcuentasrionegro.gov.ar/api/',
  baseApiUrlIntranet: 'https://tcrn-intranet-backend-testing.tribcuentasrionegro.gov.ar/api/',
  keycloakConfig,  
  production: false,
  timeOutSmall: 100000,
  timeOutLarg: 200000,
};