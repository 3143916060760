import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { AppComponent } from './app.component';
import { ConfirmViewNotificationComponent } from './mail/components/confirm-view-notification/confirm-view-notification.component';

// Interceptors
import { AuthInterceptorService } from './shared/interceptors/auth-interceptor.service';
import { HttpErrorInterceptorInterceptor } from './shared/interceptors/http-error-interceptor.interceptor';

// Locales
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
registerLocaleData(localeEsAr, 'es-Ar');

// Moduls
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';

// Routing
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmViewNotificationComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    KeycloakAngularModule,
    NgxDropzoneModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-Ar',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
