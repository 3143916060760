import { Component, OnInit } from '@angular/core';

// JWT
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  ngOnInit(): void {
    this.verifyTokenNotExpired();
  }

  private verifyTokenNotExpired(): void {
    const token: string | null = localStorage.getItem('token');

    if ( token ) {
      const tokenDecode: any = jwt_decode(token);
      const expireToken = new Date(tokenDecode.exp * 1000);
      const today = new Date();

      if ( expireToken < today ) localStorage.removeItem('token');
    }
  }

}
