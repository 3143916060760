import { Injectable } from '@angular/core';

// ENV
import { environment } from 'src/environments/environment';

// HTTP
import { HttpClient } from '@angular/common/http';

// RxJS
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { timeout } from 'rxjs/internal/operators/timeout';

@Injectable({
  providedIn: 'root'
})
export class ConfirmViewNotificationService {

  private baseApiUrl = environment.baseApiUrlChasquiExternal;
  private timeOutSmall: number = environment.timeOutSmall;
  
  constructor(
    private http: HttpClient,
  ) { }

  viewNotification( token: string ): Observable<any> {
    const url = `${this.baseApiUrl}notifications/check/${token}`;
    
    return this.http.get(
      url
    ).pipe(
      timeout( this.timeOutSmall ),
      map( ( resp: any ) => resp ),
      catchError( ( err: any ) => throwError( err ) )
    );
  }

}
