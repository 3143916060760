import { Injectable } from '@angular/core';

// HTTP
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';

// Router
import { Router } from '@angular/router';

// RxJS
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable() export class HttpErrorInterceptorInterceptor implements HttpInterceptor {
  
  constructor(
    private router: Router,
  ) { }

  intercept( req: HttpRequest<any>, next: HttpHandler ) {
    return next.handle(req).pipe(
      catchError(
        ( error: any ) => {
          let errorMessage = '';
  
          if ( error?.error?.error?.description?.includes('Unauthenticated') || error?.error?.message == 'Token inválido' || error?.error?.message?.includes('Unauthenticated.') ) {
            setTimeout( () => {
              localStorage.removeItem('token');
              this.router.navigate(['/auth/login']);
            }, 2500 );
          }
  
          if (error instanceof ErrorEvent) {
            errorMessage = `Client-side error: ${error.error.message}`;
          } else if (error.error.message == "[Keycloak Guard] Expired token") {
            localStorage.removeItem('token');
            this.router.navigate(['/auth/login']);
          }
  
          return throwError(error);
        }
      )
    );
  }
}
