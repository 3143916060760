import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { ConfirmViewNotificationComponent } from './mail/components/confirm-view-notification/confirm-view-notification.component';

// Guards
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { LoginGuard } from './shared/guards/login/login.guard';

const routes: Routes = [
  {
    path: 'notifications/check/:token',
    pathMatch: 'full',
    component: ConfirmViewNotificationComponent,
  },
  // {
  //   path: 'auth',
  //   canActivate: [ LoginGuard ],
  //   loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule )
  // },
  {
    path: 'main',
    // canActivate: [ AuthGuard ],
    loadChildren: () => import('./main/main.module').then( m => m.MainModule )
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'main',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
