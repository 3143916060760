<div class="align-items-center d-flex justify-content-center row row-main ">
    <div class="bg-white border col-11 main-col rounded">
        <div class="align-items-center d-flex justify-content-center row" style="height: 95%;">
            <div class="col-12 col-sm-10 col-md-8">
                <div class="animate__animated animate__fadeIn row text-center">
                    <div class="col-12">
                        <h1 class="title" id="title">
                            {{ loadingConfirmation ? 'Confirmando la recepción del email' : ''}} {{ !loadingConfirmation && check ? 'Usted ha confirmado la recepción del email' : ''}} {{ !loadingConfirmation && !check ? 'No se pudo realizar la recepción del email' : ''}}
                        </h1>
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col-12">
                        <p *ngIf="loadingConfirmation" class="sub-title">
                            <i class="fas fa-spinner fa-pulse mt-3" style="font-size: 2rem"></i>
                        </p>

                        <img *ngIf="!loadingConfirmation && check" class="animate__animated animate__fadeIn mt-5" src="assets/img/mail/confirm-view-notification.svg">
                        <img *ngIf="!loadingConfirmation && !check" class="animate__animated animate__fadeIn mt-5" src="assets/img/mail/denied-view-notification.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>